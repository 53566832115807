import { createStore } from 'vuex'

export default createStore({
  state: {
    pageContents: ''
  },
  getters: {
  },
  mutations: {
    changeContents(state, contents) {
      state.pageContents = contents
    }
  },
  actions: {
  },
  modules: {
  }
})
