// 导入axios实例
import httpRequest from '@/request/index'

// 定义接口的传参
interface UserInfoParam {
    type: string,
    contents: string
}

// 获取用户信息
export function apiGetUserInfo(param: UserInfoParam) {
    return httpRequest({
        url: '/saveNotes',
        method: 'post',
        data: param,
    })
}

// 获取关键词
export const getKeywords = () => httpRequest({ url: '/getNotesKeywords', method: 'get' })