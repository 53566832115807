import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/take_notes',
    name: 'take_notes',
    component: () => import(/* webpackChunkName: "take_notes" */ '../views/TakeNotesView.vue')
  },
  {
    path: '/search_notes',
    name: 'search_notes',
    component: () => import(/* webpackChunkName: "search_notes" */ '../pages/phone/SearchNotesView.vue')
  },
  {
    path: '/phone_search',
    name: 'phone_search',
    component: () => import(/* webpackChunkName: "phone_search" */ '../pages/phone/PhoneView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
